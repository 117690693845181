export default {
  common: {
    colon: '：',
    close: '關閉',
    jpy: 'JPY',
    error: '錯誤',
    contact: '洽詢',
    delete: '刪除'
  },
  email: {
    subject: '關於（{{orderId}}）訂單',
    orderId: '訂單號碼: {{orderId}}',
    contact: '負責人您好，\n<請於下方輸入洽詢內容。>'
  },
  account: {
    title: '付款方式管理',
    caption: '已登錄的信用卡',
    tips: '※最多可登錄5張信用卡。',
    deleteCardSuccess: '已刪除此信用卡。'
  },
  order: {
    bookingId: '訂單號碼',
    totalPrice: '總金額',
    useAvailableCard: '使用已登錄的信用卡',
    useNewCard: '使用新的信用卡',
    acceptedPayment: '可使用的付款方式',
    email: '電子郵件',
    deadline: '付款期限',
    pay: '{{amount}} 支付',
    error: '購買程序發生錯誤！錯誤代碼：{{code}}',
    secure: '3D Secure',
    secureMessage:
      '如欲使用本公司的服務，必須使用<a>本人驗證服務「3D Secure 2.0（EMV 3D Secure）」</a> 。',
    saveCardInfo: '儲存信用卡資訊',
    creditCardVerified: '已完成信用卡確認。',
    incompleteOrder: '訂單尚未完成。',
    clickToConfirm: '請務必點選下方確認鈕。',
    confirmOrder: '確認訂單',
    countdown: '（ {{count}} 秒）',
    paymentError: '付款程序發生錯誤。',
    confirmDeadline: '請確認訂單資訊是否正確及有效期限。',
    orderSuccess: '已確認到此訂單',
    orderSuccessSubText: '感謝您的使用',
    contact: '若問題仍未解決、\n 請點選「洽詢」選項洽詢。',
    backShop: '返回商品頁面',
    paymentDetails: '付款詳細',
    paymentLogs: '付款履歷',
    print: '列印目前頁面',
    creditCard: '信用卡',
    addCard: '新增卡片',
    paymentMethod: '付款方式'
  },
  product: {
    name: '種類',
    price: '單價',
    quantity: '數量',
    total: '金額'
  },
  transaction: {
    timestamp: '時間',
    status: '狀態',
    message: '詳細資訊',
    charge: '付款'
  },
  card: {
    cardNumber: '卡號',
    cardExpiry: '有效期限',
    cardCvc: '驗證碼',
    cardName: '信用卡上的名稱'
  },
  footer: {
    terms: '利用條約',
    privacy: '隱私權政策',
    faq: '常見疑問'
  },
  error: {
    invalidCardNumber: '此卡號無效。',
    invalidCardType: '此卡型無效。',
    invalidCardExpiry: '有效期限錯誤。',
    pastCardExpiry: '信用卡已過有效期限。',
    invalidCardCvc: '驗證碼無效。',
    invalidCardName: '請以半形字母輸入姓名。'
  }
};
